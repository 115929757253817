<template>
   <div class="yz-bbs-wrap">
       <erji-carousel></erji-carousel>
     <div class="flex">
      <div class="menu">
        <div :class="{ 'active': selectedMenu === '布雷斯特高等商学院' }" @click="selectMenu('布雷斯特高等商学院')">布雷斯特高等商学院</div>
        <div :class="{ 'active': selectedMenu === '合作办学' }" @click="selectMenu('合作办学')">合作办学</div>
        <div :class="{ 'active': selectedMenu === '精英大学' }" @click="selectMenu('精英大学')">精英大学</div>
        <div :class="{ 'active': selectedMenu === '凯拉尼亚大学' }" @click="selectMenu('凯拉尼亚大学')">凯拉尼亚大学</div>
        <div>
           拉曼大学
           <p :class="{ 'active': selectedMenu === '本科招生' }" @click="selectMenu('本科招生')">本科招生</p>
           <p :class="{ 'active': selectedMenu === '硕士招生' }" @click="selectMenu('硕士招生')">硕士招生</p>
           <p :class="{ 'active': selectedMenu === '博士招生' }" @click="selectMenu('博士招生')">博士招生</p>
        </div>

        <div :class="{ 'active': selectedMenu === '英迪大学' }" @click="selectMenu('英迪大学')">英迪大学</div>
      </div>
      <div class="cont">
        <div v-if="selectedMenu === '布雷斯特高等商学院'">
         <div class="ab-text">
            <div class="ab-title animate__animated animate__zoomIn">
              <p>—— 商学院 ——</p>
            </div>
            <div class="ab-desc animate__animated animate__zoomIn">
              <p>商学院业务概况！</p>
            </div>
         </div>
         <div class="yz-bbs-instro">
             <div class="bbs-over-box">
               <img src="../../assets/images/bbs04.png" class="b1">
               <img src="../../assets/images/bbs05.png" class="b2">
               <p class="bbs-over-text t1">
                  法国布雷斯特高等商学院
               </p>
               <img src="../../assets/images/bbs06.png" class="b3">
               <div class="bbs-over-text t2">
                  <h3> 国际学位教育 </h3>
                  <span>硕士学位：MBA、金融、市场管理、教育管理、国际商务、数字化经营与管理、房地产与经济管理</span><br>
                  <span>博士学位：DBA</span><br>
                  <span>现有学员：3500+</span><br>
               </div>
               <img src="../../assets/images/bbs07.png" class="b4">
               <p class="bbs-over-text t3">
                  其他海外高校
               </p>
               <img src="../../assets/images/bbs08.png" class="b5">
               <div class="bbs-over-text t4">
                  <h3>非学位教育</h3>
                 <span>国际证书、课程学习、国际游学</span>
               </div>
             </div>
         </div>
         <div class="bbs-back">
             <img src="../../assets/images/bbs-back.png">
         </div>
         <div class="yz-gov-con">
           <div class="gov-item">
              <img src="../../assets/images/bbs01.png">
              <p class="desc-gov">创建于1962年，是法国仅由38所 高校组成的“精英大学（校）委员 会”CGE重要成员-对等中国”985 重点工程院校”。</p>
           </div>
          <div class="gov-item">
              <img src="../../assets/images/bbs02.png">
              <p class="desc-gov">国际商务管理本科”法国排名第一， “精英高校管理学硕士”法国商学院 排名位列前三，博士项目也处前列。 2018毕业生起薪和就业率位列第一， 2019校企关系法国商学院排名第二。</p>
           </div>
           <div class="gov-item">
              <img src="../../assets/images/bbs03.png">
              <p class="desc-gov">7个国际组织认可成员12个国家（country)3000多家合作国际企业</p>
           </div>
         </div>
        </div>
        <div v-else-if="selectedMenu === '合作办学'" class="cont-item">
            <el-image v-for="url in urlsList" :key="url" :src="url" lazy></el-image>
        </div>
        <div v-else-if="selectedMenu === '精英大学'" class="cont-item">
           <el-image v-for="url in urlsList1" :key="url" :src="url" lazy></el-image>
        </div>
        <div v-else-if="selectedMenu === '凯拉尼亚大学'" class="cont-item">
            <el-image v-for="url in urlsList2" :key="url" :src="url" lazy></el-image>
        </div>
        <div v-else-if="selectedMenu === '本科招生'" class="cont-item">
            <el-image v-for="url in urlsList3" :key="url" :src="url" lazy></el-image>
        </div>
        <div v-else-if="selectedMenu === '硕士招生'" class="cont-item">
            <el-image v-for="url in urlsList4" :key="url" :src="url" lazy></el-image>
        </div>
        <div v-else-if="selectedMenu === '博士招生'" class="cont-item">
           <el-image v-for="url in urlsList5" :key="url" :src="url" lazy></el-image>
        </div>
        <div v-else-if="selectedMenu === '英迪大学'" class="cont-item">
           <el-image v-for="url in urlsList6" :key="url" :src="url" lazy></el-image>
        </div>
      </div>
      </div>
   </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{
      selectedMenu: '布雷斯特高等商学院',
      urlsList:[
          require("../../assets/images/brochure/cooperativeOffice/pic01.jpg"),
          require("../../assets/images/brochure/cooperativeOffice/pic02.jpg"),
          require("../../assets/images/brochure/cooperativeOffice/pic03.jpg"),
          require("../../assets/images/brochure/cooperativeOffice/pic04.jpg"),
          require("../../assets/images/brochure/cooperativeOffice/pic05.jpg"),
          require("../../assets/images/brochure/cooperativeOffice/pic06.jpg")
      ],
      urlsList1:[
           require("../../assets/images/brochure/eliteUniversity/pic01.jpg"),
           require("../../assets/images/brochure/eliteUniversity/pic02.jpg"),
           require("../../assets/images/brochure/eliteUniversity/pic03.jpg"),
           require("../../assets/images/brochure/eliteUniversity/pic04.jpg"),
           require("../../assets/images/brochure/eliteUniversity/pic05.jpg"),
           require("../../assets/images/brochure/eliteUniversity/pic06.jpg"),
           require("../../assets/images/brochure/eliteUniversity/pic07.jpg"),
           require("../../assets/images/brochure/eliteUniversity/pic08.jpg"),
           require("../../assets/images/brochure/eliteUniversity/pic09.jpg"),
           require("../../assets/images/brochure/eliteUniversity/pic10.jpg"),
           require("../../assets/images/brochure/eliteUniversity/pic11.jpg")
      ],
       urlsList2:[
           require("../../assets/images/brochure/kailaniaUniversity/pic01.jpg"),
           require("../../assets/images/brochure/kailaniaUniversity/pic02.jpg"),
           require("../../assets/images/brochure/kailaniaUniversity/pic03.jpg"),
           require("../../assets/images/brochure/kailaniaUniversity/pic04.jpg"),
           require("../../assets/images/brochure/kailaniaUniversity/pic05.jpg"),
           require("../../assets/images/brochure/kailaniaUniversity/pic06.jpg"),
           require("../../assets/images/brochure/kailaniaUniversity/pic07.jpg"),
           require("../../assets/images/brochure/kailaniaUniversity/pic08.jpg"),
           require("../../assets/images/brochure/kailaniaUniversity/pic09.jpg")
      ],
       urlsList3:[
           require("../../assets/images/brochure/ramanRegular/pic01.jpg"),
           require("../../assets/images/brochure/ramanRegular/pic02.jpg"),
           require("../../assets/images/brochure/ramanRegular/pic03.jpg"),
           require("../../assets/images/brochure/ramanRegular/pic04.jpg"),
           require("../../assets/images/brochure/ramanRegular/pic05.jpg"),
           require("../../assets/images/brochure/ramanRegular/pic06.jpg"),
           require("../../assets/images/brochure/ramanRegular/pic07.jpg"),
           require("../../assets/images/brochure/ramanRegular/pic08.jpg"),
           require("../../assets/images/brochure/ramanRegular/pic09.jpg")
      ],
      urlsList4:[
           require("../../assets/images/brochure/remanMaster/pic01.jpg"),
           require("../../assets/images/brochure/remanMaster/pic02.jpg"),
           require("../../assets/images/brochure/remanMaster/pic03.jpg"),
           require("../../assets/images/brochure/remanMaster/pic04.jpg"),
           require("../../assets/images/brochure/remanMaster/pic05.jpg"),
           require("../../assets/images/brochure/remanMaster/pic06.jpg"),
           require("../../assets/images/brochure/remanMaster/pic07.jpg"),
           require("../../assets/images/brochure/remanMaster/pic08.jpg"),
           require("../../assets/images/brochure/remanMaster/pic09.jpg"),
           require("../../assets/images/brochure/remanMaster/pic10.jpg"),
           require("../../assets/images/brochure/remanMaster/pic11.jpg"),
           require("../../assets/images/brochure/remanMaster/pic12.jpg"),
           require("../../assets/images/brochure/remanMaster/pic13.jpg"),
           require("../../assets/images/brochure/remanMaster/pic14.jpg"),
           require("../../assets/images/brochure/remanMaster/pic15.jpg"),
           require("../../assets/images/brochure/remanMaster/pic16.jpg"),
           require("../../assets/images/brochure/remanMaster/pic17.jpg"),
           require("../../assets/images/brochure/remanMaster/pic18.jpg")
      ],
        urlsList5:[
           require("../../assets/images/brochure/remanDoctor/pic01.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic02.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic03.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic04.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic05.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic06.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic07.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic08.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic09.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic10.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic11.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic12.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic13.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic14.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic15.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic16.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic17.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic18.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic19.jpg"),
           require("../../assets/images/brochure/remanDoctor/pic20.jpg")
      ],
        urlsList6:[
           require("../../assets/images/brochure/intiUniversity/pic01.jpg"),
           require("../../assets/images/brochure/intiUniversity/pic02.jpg"),
           require("../../assets/images/brochure/intiUniversity/pic03.jpg"),
           require("../../assets/images/brochure/intiUniversity/pic04.jpg"),
           require("../../assets/images/brochure/intiUniversity/pic05.jpg"),
           require("../../assets/images/brochure/intiUniversity/pic06.jpg")
      ],
    }
  },
  methods:{
    selectMenu(menu) {
      this.selectedMenu = menu;
    }
  }
}
</script>
<style lang="less" scoped>
.flex{
  display: flex;
  justify-content: space-between;
  padding:30px 0;
  width: 1200px;
  margin: 0 auto;
  .active{
     color:#0184d2
  }
  .menu{
    text-align: left;
    width: 15%;
    div{
      line-height: 40px;
      cursor: pointer;
      border-bottom: 1px solid #ccc;
      padding-left: 10px;
    }
    p{
      margin-left: 50px;
    }
  }
  .cont{
    width: 80%;
    background: #f7f7f7;
    padding:15px;
    .cont-item{
       img{
         width: 100%;
        }
    }
  }
}
.ab-text{
    margin:30px  0px;
    .ab-title{
       text-align: center;
       font-size: 30px;
       color:#0184d2;
    }
  .ab-desc{
    margin-top:10px;
    text-align: center;
    font-size: 14px;
    color: #656d78;
  }
}
  .yz-bbs-wrap{
     .yz-bbs-instro{
       width: 100%;
       margin:0 auto;
       .bbs-over-box{
         position: relative;
         height: 560px;
       }
       .b1{
         position: absolute;
         top:80px;
         left:477px;
       }
       .b2{
         position: absolute;
         top:80px;
         left:240px;
       }
       .b3{
         position: absolute;
         top:240px;
         left:240px;
       }
       .b4{
         position: absolute;
         top:80px;
         right:240px;
       }
       .b5{
         position: absolute;
         top:240px;
         right:240px;
       }
       .bbs-over-text {
         width: 309px;
         padding:20px 10px;
         background: #E5F5FF;
         border-radius: 10px;
         color: #333;
         font-size: 20px;
         line-height: 27px;
         span{
           font-size: 16px;

         }
       }
       .t1{
         position: absolute;
         top:0px;
         left:100px;
       }
       .t2{
          position: absolute;
         top:320px;
         left:100px;
       }
       .t3{
         position: absolute;
         top:0px;
         right:100px;
       }
       .t4{
         position: absolute;
         top:320px;
         right:100px;
       }
     }
     .bbs-back{
      width: 100%;
       height: auto;
       margin:0 auto;
       img{
         width: 100%;
         margin-bottom: 40px;
       }
    }
     .yz-gov-con{
       width:100%;
       //margin:0 auto;
       display: flex;
        justify-content: center;/*水平居中*/
		    align-items: center;/*垂直居中*/
       .gov-item{
         width: 30%;
         height:300px;
         margin-right:20px;
         img{
           margin-bottom: 30px;
         }
         .title-gov{
           margin-top:20px;
           font-size:20px;
         }
         .desc-gov{
           font-size: 16px;
           line-height: 30px;
         }
       }
     }
  }
</style>
